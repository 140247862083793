<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, customFields, tags, metadata, name, key, description, unitOfMeasure, constraints]
    x-vue-custom-fields: [ customFields ]
    x-vue-tags: [ tags ]
    x-vue-metadata: [ metadata ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="actionTypes"
  />
</template>
<script>
  import { actionTypes } from '@/config/descriptions/actionTypes';
  import { actionTypesTexts } from '@/config/pageTexts/actionTypes.json';

  export default {
    name: 'ActionTypesTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'actionTypes/actionTypes',
            fields: 'actionTypes/fields',
            sortableFields: 'actionTypes/sortableFields',
            pages: 'actionTypes/pages',
            loading: 'actionTypes/loading',
            resultCount: 'actionTypes/resultCount',
            totalRecords: 'actionTypes/totalRecords',
            originalFields: 'actionTypes/originalFields',
            searchableAllFields: 'actionTypes/searchableAllFields',
            success: 'actionTypes/success',
            message: 'actionTypes/message',
          },
          actions: {
            fields: 'actionTypes/handleFields',
            delete: 'actionTypes/handleDeleteActionTypes',
            reset: 'actionTypes/handleClearFilter',
            query: 'actionTypes/handleGetActionTypesByQuery',
          },
        },
        settings: {
          describe: { ...actionTypes },
          page: { ...actionTypesTexts },
        },
        route: {
          create: 'CreateActionType',
          edit: 'EditActionType',
          preview: 'PreviewActionType',
        },
      };
    },
  };
</script>